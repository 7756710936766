<template>
  <div>
    <v-form @submit="onSubmit">
      <v-container>
        <v-row>
          <v-col cols="12">
            <span>L'intervallo temporale massimo consentito è 365 giorni</span>
          </v-col>
          <v-col md="6" cols="12">
            <v-menu
              v-model="showPickerFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              z-index="2000"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Data inizio"
                  readonly
                  filled
                  dense
                  aria-label="Imposta data inizio"
                  append-icon="mdi-calendar"
                  @click:append="showPickerFrom = !showPickerFrom"
                  v-on="on"
                  :value="selectedDateFrom"
                ></v-text-field>
              </template>
              <v-date-picker
                id="stime-datepicker"
                v-model="locForm.dataInizio"
                no-title
                locale="it-IT"
                :max="maxDate"
                :min="minDate"
                @input="showPickerFrom = !showPickerFrom"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" cols="12">
            <v-menu
              v-model="showPickerTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              z-index="2000"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Data fine"
                  readonly
                  filled
                  dense
                  aria-label="Imposta data fine"
                  append-icon="mdi-calendar"
                  @click:append="showPickerTo = !showPickerTo"
                  v-on="on"
                  :value="selectedDateTo"
                ></v-text-field>
              </template>
              <v-date-picker
                id="stime-datepicker"
                v-model="locForm.dataFine"
                no-title
                locale="it-IT"
                :max="maxDate"
                :min="minDate"
                @input="showPickerTo = !showPickerTo"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="8" sm="12">
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                $v.locForm.dataInizio.$dirty && !$v.locForm.dataInizio.required
              "
            >
              La data di inizio periodo è obbligatoria.
            </v-alert>
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                $v.locForm.dataInizio.$dirty &&
                  !$v.locForm.dataInizio.previousAlFailed
              "
            >
              La data di inizio periodo non può essere successiva a quella di
              fine periodo.
            </v-alert>
            <v-alert
              dense
              outlined
              type="error"
              v-if="$v.locForm.dataFine.$dirty && !$v.locForm.dataFine.required"
            >
              La data di fine periodo è obbligatoria.
            </v-alert>
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                $v.locForm.dataInizio.$dirty && !$v.locForm.dataInizio.maxPeriod
              "
            >
              L'intervallo temporale massimo consentito è 365 giorni
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-checkbox
              id="checkboxReti"
              v-model="locForm.checkReti"
              label="includi le reti private non validate da ARPA Piemonte"
              @change="fetchStazioni()"
            ></v-checkbox>
            <v-select
              filled
              dense
              v-model="locForm.provincia"
              label="Ambito provinciale"
              aria-label="Seleziona ambito provinciale"
              :items="province"
              :disabled="!provEnabled"
              no-data-text="Nessun dato disponibile"
              :state="
                $v.locForm.provincia.$dirty
                  ? !$v.locForm.provincia.$error
                  : null
              "
              required
              @change="fetchStazioni()"
            ></v-select>
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                $v.locForm.provincia.$dirty && !$v.locForm.provincia.required
              "
            >
              Specificare l'ambito provinciale.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-select
              filled
              dense
              id="stazioni"
              v-model="locForm.stazione"
              label="Stazione di Rilevamento"
              aria-label="Seleziona stazione di rilevamento"
              :items="stazioni"
              :disabled="!stazEnabled"
              no-data-text="Nessun dato disponibile"
              :state="
                $v.locForm.stazione.$dirty ? !$v.locForm.stazione.$error : null
              "
              required
              @change="
                fetchCategorieInquinanti();
                fetchDettaglioStazione();
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-select
              filled
              dense
              id="categorieInquinanti"
              v-model="locForm.categoriaInquinante"
              label="Categoria inquinante"
              aria-label="Seleziona categoria inquinante"
              item-text="text"
              :items="categorieInquinanti"
              :disabled="!catEnabled"
              no-data-text="Nessun dato disponibile"
              return-object
              :state="
                $v.locForm.categoriaInquinante.$dirty
                  ? !$v.locForm.categoriaInquinante.$error
                  : null
              "
              required
              @change="
                resetTipologie();
                fetchTipologieInquinanti();
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-select
              filled
              dense
              id="tipologieInquinanti"
              v-model="locForm.tipologieSelected"
              label="Tipologia inquinante"
              aria-label="Seleziona tipologia inquinante"
              :items="tipologieInquinanti"
              :disabled="!tipEnabled"
              no-data-text="Nessun dato disponibile"
              required
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-btn
              ref="risultati"
              id="searchBtn"
              type="submit"
              block
              class="primary"
              :disabled="!searchEnabled"
            >
              Visualizza dati
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" class="p-0">
            <v-alert v-if="serviceError" dense outlined type="error">
              Errore nel caricamento dei dati.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container v-if="showTable">
      <h3 class="text-light-blue">Risultati della ricerca</h3>
      <v-row>
        <v-col md="6">
          <p>
            Stazione
            <v-chip class="ma-2">
              <v-avatar left>
                <v-icon>mdi-map-marker</v-icon>
              </v-avatar>
              {{ nomeStazione }}
            </v-chip>
          </p>
          <p>
            Dal
            <v-chip class="ma-2">
              <v-avatar left>
                <v-icon>mdi-calendar</v-icon>
              </v-avatar>
              {{ moment(locForm.dataInizio).format("DD/MM/YYYY") }}
            </v-chip>
            Al
            <v-chip class="ma-2">
              <v-avatar left>
                <v-icon>mdi-calendar</v-icon>
              </v-avatar>
              {{ moment(locForm.dataFine).format("DD/MM/YYYY") }}
            </v-chip>
          </p>
        </v-col>
        <v-col md="6">
          <v-expansion-panels inset>
            <v-expansion-panel key="stazionePanel">
              <v-expansion-panel-header>
                Informazioni sulla stazione
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <strong>Indirizzo:</strong>
                {{ dettaglioStazione.indirizzo_localita }}
                <br />
                <strong>Quota:</strong>
                {{ dettaglioStazione.quota_stazione }} m.
                <br />
                <strong>Codice EOI:</strong>
                {{ dettaglioStazione.codice_eoi }}
                <br />
                <strong>Zona:</strong>
                {{ zonaStazione }}
                <br />
                <strong>Data inizio attività:</strong>
                {{ moment(dettaglioStazione.data_inizio).format("DD/MM/YYYY") }}
                <br />
                <p style="float:right;" v-if="dettaglioStazione.url != null">
                  <a :href="dettaglioStazione.url" target="_blank">
                    Scopri di più su arpa.piemonte.it
                  </a>
                  <v-icon>mdi-open-in-new</v-icon>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableFields"
        :items="rilevamenti"
        :options.sync="options"
        :server-items-length="totalRows"
        class="elevation-1"
        fixed-header
        height="450px"
        hide-default-footer
        no-data-text="Nessun dato disponibile"
        :loading="loading"
        @page-count="pageCount = $event"
      >
        <template v-slot:item.stato="{ item }">
          {{ getDescStato(item.stato) }}
        </template>
      </v-data-table>
      <v-row>
        <v-col md="2">
          <v-select
            label="Visualizza"
            :items="itemsPerPageOptions"
            v-model="options.itemsPerPage"
          ></v-select>
        </v-col>
        <v-col md="10">
          <v-pagination
            v-model="options.page"
            :length="pageCount"
            total-visible="7"
            color="#073043"
            class="float-right"
          >
          </v-pagination>
        </v-col>
      </v-row>
      <span class="float-right" style="display:inline">
        <div class="inline-div">
          <span class="inline-elem">
            <v-btn
              class="primary"
              @click="downloadRilevamenti($event)"
              :disabled="
                rilevamenti == undefined ||
                  rilevamenti == null ||
                  rilevamenti.length == 0
              "
            >
              Scarica CSV
            </v-btn>
          </span>
          <v-btn
            class="primary"
            @click="downloadRilevamentiZip($event)"
            :disabled="
              rilevamenti == undefined ||
                rilevamenti == null ||
                rilevamenti.length == 0
            "
          >
            Scarica ZIP
          </v-btn>
        </div>
      </span>
    </v-container>
  </div>
</template>

<script>
import {
  FETCH_AREE_TERRITORIALI,
  FETCH_RILEVAMENTI_STAZIONI,
  FETCH_STAZIONI,
  FETCH_PROVINCE,
  FETCH_CATEGORIE_INQUINANTI,
  FETCH_TIPOLOGIE_INQUINANTI,
  FETCH_PARAMETRI,
  FETCH_DETTAGLIO_STAZIONE
} from "@/store/actions.type";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { alphaNum, required } from "vuelidate/lib/validators";
import { notFutureDate } from "@/validators/ariaweb.validator";

export default {
  name: "RicercaMisure",
  data() {
    let today = new moment();
    let yesterday = moment(today).subtract(1, "day");
    let max = moment(yesterday);
    //let min = moment(today).subtract(1, "year");
    let min = new moment("2000-01-01", "YYYY-MM-DD");

    return {
      locForm: {
        dataInizio: yesterday.format("YYYY-MM-DD"),
        dataFine: yesterday.format("YYYY-MM-DD"),
        provincia: null,
        stazione: null,
        categoriaInquinante: null,
        tipologieSelected: [],
        checkReti: false
      },
      serviceError: false,
      showPickerFrom: false,
      showPickerTo: false,
      maxDate: max.format("YYYY-MM-DD"),
      minDate: min.format("YYYY-MM-DD"),
      province: [],
      stazioni: [],
      categorieInquinanti: [],
      tipologieInquinanti: [],
      parametri: [],
      rilevamenti: [],
      dettaglioStazione: null,
      zonaStazione: null,
      totalRows: 0,
      loading: false,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 24
        //sortBy: ["dataora"],
        //sortDesc: [false]
      },
      itemsPerPageOptions: [24, 48],
      tableFields: [
        {
          value: "desc_parametro",
          text: "Inquinante",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "dataora",
          text: "Data e Ora",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "valore",
          text: "Valore",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "stato",
          text: "Validazione dato",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "desc_unita_misura",
          text: "Unità di misura",
          sortable: false,
          class: "grey--text text--darken-4"
        }
      ],
      showTable: false,
      showDetail: true
    };
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.serviceError = false;
        this.getDataFromApi().then(data => {
          this.rilevamenti = data.rilevamenti;
          this.totalRows = data.totalRows;
          this.showTable = true;
          this.loading = false;
        });
      },
      deep: true
    },
    selectedDateFrom: function(newValue, oldValue) {
      this.resetFields();
    },
    selectedDateTo: function(newValue, oldValue) {
      this.resetFields();
    }
  },
  computed: {
    provEnabled: function() {
      return this.locForm.dataInizio != null && this.locForm.dataFine != null;
    },
    stazEnabled: function() {
      return this.locForm.provincia != null;
    },
    catEnabled: function() {
      return this.locForm.stazione != null;
    },
    tipEnabled: function() {
      return this.locForm.categoriaInquinante != null;
    },
    searchEnabled: function() {
      return this.locForm.tipologieSelected.length > 0;
    },
    showingRows: function() {
      return this.totalRows <= this.options.itemsPerPage
        ? this.totalRows
        : this.options.itemsPerPage;
    },
    selectedDateFrom: function() {
      if (this.locForm.dataInizio == null) return null;
      return moment(this.locForm.dataInizio).format("DD/MM/YYYY");
    },
    selectedDateTo: function() {
      if (this.locForm.dataFine == null) return null;
      return moment(this.locForm.dataFine).format("DD/MM/YYYY");
    },
    nomeStazione: function() {
      let nome = "";
      if (this.dettaglioStazione != null) {
        nome =
          this.dettaglioStazione.nome_pubblico != null &&
          this.dettaglioStazione.nome_pubblico != ""
            ? this.dettaglioStazione.nome_pubblico
            : this.dettaglioStazione.denominazione;
      }
      return nome;
    }
  },
  mixins: [validationMixin],
  validations: {
    locForm: {
      dataInizio: {
        required,
        notFutureDate,
        maxPeriod: function() {
          return this.checkPeriodRicerca();
        },
        previousAlFailed: function() {
          return this.checkDateRicerca();
        }
      },
      dataFine: {
        required,
        notFutureDate
      },
      provincia: { required },
      stazione: { required },
      categoriaInquinante: { required }
    }
  },
  methods: {
    moment: function(dateStr) {
      return moment(dateStr);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.ricerca();
    },
    reset() {
      this.locForm = {
        dataInizio: null,
        dataFine: null,
        provincia: null,
        stazione: null,
        categoriaInquinante: null,
        tipologieSelected: [],
        checkReti: false
      };
      this.categorieInquinanti = [];
      this.tipologieInquinanti = [];
      this.showTable = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    resetFields() {
      this.locForm.provincia = null;
      this.locForm.stazione = null;
      this.locForm.categoriaInquinante = null;
      this.locForm.tipologieSelected = [];
      this.locForm.checkReti = false;
      this.fetchStazioni();
    },
    checkPeriodRicerca() {
      let mDal = moment(this.locForm.dataInizio);
      let mAl = moment(this.locForm.dataFine);

      if (mAl.diff(mDal, "days") > 365) {
        return false;
      }
      return true;
    },
    checkDateRicerca() {
      if (this.locForm.dataInizio == null || this.locForm.dataFine == null)
        return true;
      const dateDal = this.getPeriodoDa().getTime();
      const dateAl = this.getPeriodoA().getTime();
      if (isNaN(dateDal) || isNaN(dateAl)) {
        return true;
      }
      if (dateDal - dateAl > 0) {
        return false;
      }
      return true;
    },
    fetchProvince() {
      this.$store
        .dispatch(FETCH_PROVINCE)
        .then(data => {
          this.province = this.formatProvince(data);
          if (this.province.length > 0)
            this.province.unshift({
              text: "Scegli l'ambito provinciale",
              value: null
            });
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    formatProvince(data) {
      let listaProvince = [];
      data.forEach(prov => {
        let name = prov.text.toUpperCase();
        let res = name.replace("PROVINCIA DI", "");
        let res2 = res.replace("PROVINCIA DEL", "");
        prov.text = res2;
        listaProvince.push(prov);
      });
      listaProvince = listaProvince.sort((p1, p2) => {
        let s1 = p1.text;
        let s2 = p2.text;
        return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
      });
      return listaProvince;
    },
    fetchStazioni() {
      if (!this.stazEnabled) return;
      this.resetCategorie();
      this.resetTipologie();
      const dateStart = new Date(
        this.locForm.dataInizio + "T00:00:00"
      ).getTime();
      const dateEnd = new Date(this.locForm.dataFine + "T23:59:59").getTime();
      this.$store
        .dispatch(FETCH_STAZIONI, {
          keyProvincia: this.locForm.provincia,
          beginDate: dateStart,
          endDate: dateEnd,
          includePrivateStations: this.locForm.checkReti
        })
        .then(data => {
          this.stazioni = data;
          if (this.stazioni.length > 0)
            this.stazioni.unshift({
              text: "Scegli la stazione",
              value: null
            });
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    fetchDettaglioStazione() {
      if (this.locForm.stazione == null) return;
      const dateStart = new Date(
        this.locForm.dataInizio + "T00:00:00"
      ).getTime();
      const dateEnd = new Date(this.locForm.dataFine + "T23:59:59").getTime();
      this.$store
        .dispatch(FETCH_DETTAGLIO_STAZIONE, {
          keyStazione: this.locForm.stazione,
          beginDate: dateStart,
          endDate: dateEnd
        })
        .then(response => {
          this.dettaglioStazione = response.data.records[0];
          this.zonaStazione = this.getZonaStazione();
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    getZonaStazione: function() {
      let zona = "";
      if (this.dettaglioStazione != null) {
        switch (this.dettaglioStazione.id_tp_zona) {
          case 1:
            zona = "urbana";
            break;
          case 2:
            zona = "suburbana";
            break;
          case 3:
            zona = "rurale";
            break;
          case 4:
            zona = "rurale vicino alla città";
            break;
          case 5:
            zona = "rurale-regionale";
            break;
          case 6:
            zona = "rurale-remota";
            break;
          default:
            break;
        }
      }
      return zona;
    },
    fetchCategorieInquinanti() {
      if (!this.catEnabled) return;
      this.resetTipologie();
      const dateStart = new Date(
        this.locForm.dataInizio + "T00:00:00"
      ).getTime();
      const dateEnd = new Date(this.locForm.dataFine + "T23:59:59").getTime();
      this.$store
        .dispatch(FETCH_CATEGORIE_INQUINANTI, {
          keyStazione: this.locForm.stazione,
          beginDate: dateStart,
          endDate: dateEnd
        })
        .then(data => {
          this.categorieInquinanti = data;
          if (this.categorieInquinanti.length > 0)
            this.categorieInquinanti.unshift({
              text: "Scegli la categoria",
              value: null
            });
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    fetchTipologieInquinanti() {
      if (!this.tipEnabled) return;
      this.showTable = false;
      const dateStart = new Date(
        this.locForm.dataInizio + "T00:00:00"
      ).getTime();
      const dateEnd = new Date(this.locForm.dataFine + "T23:59:59").getTime();
      this.$store
        .dispatch(FETCH_TIPOLOGIE_INQUINANTI, {
          keyCategoria: this.locForm.categoriaInquinante.value,
          beginDate: dateStart,
          endDate: dateEnd
        })
        .then(data => {
          this.tipologieInquinanti = data;
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    resetCategorie() {
      this.categorieInquinanti = [];
      this.locForm.categoriaInquinante = null;
      this.showTable = false;
    },
    resetTipologie() {
      this.tipologieInquinanti = [];
      this.locForm.tipologieSelected = [];
      this.parametri = [];
      this.showTable = false;
    },
    getPeriodoDa() {
      return new Date(this.locForm.dataInizio.toString());
    },
    getPeriodoA() {
      return new Date(this.locForm.dataFine.toString());
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {
        //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const { page, itemsPerPage } = this.options;
        let params = this.getInputParamsRicerca(
          (page - 1) * itemsPerPage,
          itemsPerPage,
          "json"
        );
        this.$store
          .dispatch(FETCH_RILEVAMENTI_STAZIONI, params)
          .then(result => {
            let rilevamenti = result.data.risultati;
            rilevamenti.map(function(item) {
              item.dataora = item.data + " " + item.ora;
            });
            let totalRows = result.data.resultTotalCount;
            /*if (sortBy.length === 1 && sortDesc.length === 1) {
              rilevamenti = rilevamenti.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }*/
            resolve({
              rilevamenti,
              totalRows
            });
          })
          .catch(error => {
            //console.log("Error", { error });
            this.serviceError = true;
            let ret = [];
            let tot = 0;
            resolve({
              ret,
              tot
            });
          });
      });
    },
    ricerca() {
      this.$v.locForm.$touch();
      if (this.$v.locForm.$invalid) return;
      this.$vuetify.goTo(this.$refs.risultati);
      this.fetchParametri();
    },
    fetchParametri() {
      const dateStart = new Date(
        this.locForm.dataInizio + "T00:00:00"
      ).getTime();
      const dateEnd = new Date(this.locForm.dataFine + "T23:59:59").getTime();
      let parametri = [];
      this.showTable = false;
      this.$store
        .dispatch(FETCH_PARAMETRI, {
          tipologieSelected: this.locForm.tipologieSelected,
          beginDate: dateStart,
          endDate: dateEnd
        })
        .then(responses => {
          responses.forEach(response => {
            let paramsArray = response.data;
            paramsArray.forEach(element => {
              let pArray = element.key.split(".");
              var parametro = pArray[2];
              let len = pArray.length;
              if (len > 3) {
                let i = 3;
                while (i < len) {
                  parametro = parametro + "." + pArray[i];
                  i++;
                }
              }
              parametri.push(parametro);
            });
          });
          this.parametri = parametri;
          this.showTable = true;
          this.options = { page: 1, itemsPerPage: 24 };
        })
        .catch(error => {
          //console.log("Error", { error });
        });
    },
    downloadRilevamenti(event) {
      this.$store
        .dispatch(
          FETCH_RILEVAMENTI_STAZIONI,
          this.getInputParamsRicerca(0, this.options.itemsPerPage, "csv")
        )
        .then(({ data }) => {
          let anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(data);
          anchor.target = "_blank";
          anchor.download = this.getFileName();
          anchor.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
        })
        .catch(error => {});
    },
    getFileName() {
      let nomestazione = this.nomeStazione.replace(/ /g, "").replace("'", "");
      let categoria = this.locForm.categoriaInquinante.text
        .replace(/ /g, "-")
        .replace("'", "");
      let from = this.locForm.dataInizio;
      let to = this.locForm.dataFine;
      return nomestazione + "_" + categoria + "_" + from + "_" + to + ".csv";
    },
    downloadRilevamentiZip(event) {
      this.$store
        .dispatch(
          FETCH_RILEVAMENTI_STAZIONI,
          this.getInputParamsRicerca(0, this.options.itemsPerPage, "zip")
        )
        .then(({ data }) => {
          let anchor = document.createElement("a");
          let blob = new Blob([data], { type: "application/octet-stream" });
          anchor.href = URL.createObjectURL(blob);
          anchor.download =
            "ariaweb_misure_" + this.dettaglioStazione.codice_eoi + ".zip";
          anchor.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
        })
        .catch(error => {});
    },
    getInputParamsRicerca(offset, limit, formato) {
      const inStazioni = [];
      inStazioni.push({
        id_stazione: this.locForm.stazione,
        parametri: this.parametri
      });
      const inParams = {
        offset: offset,
        limit: limit,
        periodo_da: moment(this.locForm.dataInizio).format("DDMMYYYY"),
        periodo_a: moment(this.locForm.dataFine).format("DDMMYYYY"),
        stazioni: inStazioni,
        flag_validato: false,
        formato: formato
      };
      return inParams;
    },
    getDescStato: function(stato) {
      let desc = "";
      switch (stato) {
        case "P":
          desc = "Dato non acora verificato";
          break;
        case "M":
          desc = "Dato mancante";
          break;
        case "E":
          desc = "Dato non valido";
          break;
        case "V":
          desc = "Dato valido";
          break;
        default:
          break;
      }
      return desc;
    }
  },
  async mounted() {
    this.fetchProvince();
  }
};
</script>
