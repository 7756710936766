<template>
  <v-container id="content">
    <v-form ref="form" lazy-validation @submit="onSubmit">
      <v-container>
        <v-row>
          <v-col md="6">
            <v-select
              filled
              dense
              v-model="indForm.anno"
              :items="anni"
              :state="$v.indForm.anno.$dirty ? !$v.indForm.anno.$error : null"
              label="Anno"
              aria-label="Seleziona anno"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-select
              filled
              dense
              v-model="indForm.provincia"
              :items="province"
              :disabled="!provEnabled"
              no-data-text="Nessun dato disponibile"
              :state="
                $v.indForm.provincia.$dirty
                  ? !$v.indForm.provincia.$error
                  : null
              "
              label="Ambito provinciale"
              aria-label="Seleziona ambito provinciale"
              required
              @change="fetchStazioni()"
            ></v-select>
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                $v.indForm.provincia.$dirty && !$v.indForm.provincia.required
              "
            >
              Specificare la provincia.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-select
              filled
              dense
              v-model="indForm.stazione"
              :items="stazioni"
              :disabled="!stazEnabled"
              no-data-text="Nessun dato disponibile"
              :state="
                $v.indForm.provincia.$dirty
                  ? !$v.indForm.provincia.$error
                  : null
              "
              label="Stazione di Rilevamento"
              aria-label="Seleziona stazione di rilevamento"
              required
              @change="fetchDettaglioStazione()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-btn
              ref="risultati"
              id="searchBtn2"
              type="submit"
              block
              class="primary"
              :disabled="!searchEnabled"
            >
              Visualizza dati
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container v-if="showTable">
      <h3 class="text-light-blue">Risultati della ricerca</h3>
      <v-row>
        <v-col md="5">
          <p>
            Stazione
            <v-chip class="ma-2">
              <v-avatar left>
                <v-icon>mdi-map-marker</v-icon>
              </v-avatar>
              {{ dettaglioStazione.denominazione }}
            </v-chip>
          </p>
          <p>
            Anno
            <v-chip class="ma-2">
              <v-avatar left>
                <v-icon>mdi-calendar</v-icon>
              </v-avatar>
              {{ indForm.anno }}
            </v-chip>
          </p>
        </v-col>
        <v-col md="7">
          <v-expansion-panels inset>
            <v-expansion-panel key="stazionePanel">
              <v-expansion-panel-header>
                Informazioni sulla stazione
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <strong>Indirizzo:</strong>
                {{ dettaglioStazione.indirizzo_localita }}
                <br />
                <strong>Quota:</strong>
                {{ dettaglioStazione.quota_stazione }} m.
                <br />
                <strong>Codice EOI:</strong>
                {{ dettaglioStazione.codice_eoi }}
                <br />
                <strong>Zona:</strong>
                {{ zonaStazione }}
                <br />
                <strong>Data inizio attività:</strong>
                {{ moment(dettaglioStazione.data_inizio).format("DD/MM/YYYY") }}
                <br />
                <p style="float: right" v-if="dettaglioStazione.url != null">
                  <a :href="dettaglioStazione.url" target="_blank">
                    Scopri di più su arpa.piemonte.it
                  </a>
                  <v-icon>mdi-open-in-new</v-icon>
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableFields"
        :items="indicatori"
        no-data-text="Nessun dato disponibile"
        class="elevation-1 aria-table"
        hide-default-footer
        disable-pagination
      ></v-data-table>

      <div class="float-right">
        <v-btn
          class="primary"
          @click="downloadIndicatori()"
          :disabled="indicatori.length == 0"
        >
          Scarica CSV
        </v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import {
  FETCH_INDICATORI_STAZIONI,
  FETCH_STAZIONI,
  FETCH_PROVINCE,
  FETCH_DETTAGLIO_STAZIONE
} from "@/store/actions.type";
import { validationMixin } from "vuelidate";
import moment from "moment";
import { alphaNum, required } from "vuelidate/lib/validators";

export default {
  name: "RicercaIndicatori",
  data() {
    return {
      indForm: {
        anno: null,
        provincia: null,
        stazione: null,
        checkReti: false
      },
      anni: [],
      province: [],
      stazioni: [],
      indicatori: [],
      dettaglioStazione: null,
      zonaStazione: null,
      tableFields: [
        {
          value: "nome_parametro",
          text: "Inquinante",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "nome_aggregazione",
          text: "Rilevamento",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "limite",
          text: "Limite",
          sortable: false,
          class: "grey--text text--darken-4"
        },
        {
          value: "valore",
          text: "Dato rilevato",
          sortable: false,
          class: "grey--text text--darken-4"
        }
      ],
      showTable: false,
      showDetail: true
    };
  },
  computed: {
    provEnabled: function () {
      return this.indForm.anno != null;
    },
    stazEnabled: function () {
      return this.indForm.provincia != null;
    },
    searchEnabled: function () {
      return this.indForm.stazione != null;
    }
  },
  mixins: [validationMixin],
  validations: {
    indForm: {
      anno: { required },
      provincia: { required },
      stazione: { required }
    }
  },
  methods: {
    moment: function (dateStr) {
      return moment(dateStr);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.ricerca();
    },
    reset() {
      this.indForm = {
        anno: null,
        provincia: null,
        stazione: null,
        checkReti: false
      };
      this.showTable = false;
      this.stazioni = [];
      this.indicatori = [];
      this.dettaglioStazione = null;
      this.zonaStazione = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    fetchProvince() {
      this.$store
        .dispatch(FETCH_PROVINCE)
        .then((data) => {
          this.province = this.formatProvince(data);
          if (this.province.length > 0)
            this.province.unshift({
              text: "Scegli l'ambito provinciale",
              value: null
            });
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    formatProvince(data) {
      let listaProvince = [];
      data.forEach((prov) => {
        let name = prov.text.toUpperCase();
        let res = name.replace("PROVINCIA DI", "");
        let res2 = res.replace("PROVINCIA DEL", "");
        prov.text = res2;
        listaProvince.push(prov);
      });
      listaProvince = listaProvince.sort((p1, p2) => {
        let s1 = p1.text;
        let s2 = p2.text;
        return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
      });
      return listaProvince;
    },
    fetchStazioni() {
      if (!this.stazEnabled) return;
      this.resetStazione();
      const dateStart = new Date(this.getPeriodoDa()).getTime();
      const dateEnd = new Date(this.getPeriodoA()).getTime();
      this.$store
        .dispatch(FETCH_STAZIONI, {
          keyProvincia: this.indForm.provincia,
          beginDate: dateStart,
          endDate: dateEnd,
          includePrivateStations: this.indForm.checkReti
        })
        .then((data) => {
          this.stazioni = data;
          if (this.stazioni.length > 0)
            this.stazioni.unshift({
              text: "Scegli la stazione",
              value: null
            });
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    fetchDettaglioStazione() {
      if (!this.indForm.stazione == null) return;
      const dateStart = new Date(this.getPeriodoDa()).getTime();
      const dateEnd = new Date(this.getPeriodoA()).getTime();
      this.$store
        .dispatch(FETCH_DETTAGLIO_STAZIONE, {
          keyStazione: this.indForm.stazione,
          beginDate: dateStart,
          endDate: dateEnd
        })
        .then((response) => {
          this.dettaglioStazione = response.data.records[0];
          this.zonaStazione = this.getZonaStazione();
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    getZonaStazione: function () {
      let zona = "";
      if (this.dettaglioStazione != null) {
        switch (this.dettaglioStazione.id_tp_zona) {
          case 1:
            zona = "urbana";
            break;
          case 2:
            zona = "suburbana";
            break;
          case 3:
            zona = "rurale";
            break;
          case 4:
            zona = "rurale vicino alla città";
            break;
          case 5:
            zona = "rurale-regionale";
            break;
          case 6:
            zona = "rurale-remota";
            break;
          default:
            break;
        }
      }
      return zona;
    },
    resetStazione() {
      this.indForm.stazione = null;
      this.showTable = false;
    },
    getPeriodoDa() {
      return new Date("01/01/" + this.indForm.anno);
    },
    getPeriodoA() {
      return new Date("12/31/" + this.indForm.anno);
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        ""
      );
    },
    ricerca() {
      this.$v.indForm.$touch();
      if (this.$v.indForm.$invalid) return;
      this.$vuetify.goTo(this.$refs.risultati);
      this.showTable = false;
      this.fetchIndicatori();
    },
    fetchIndicatori() {
      this.$store
        .dispatch(FETCH_INDICATORI_STAZIONI, {
          anno: this.indForm.anno,
          keyStazione: this.indForm.stazione
        })
        .then((response) => {
          this.indicatori = response.data;
          this.showTable = true;
        })
        .catch((error) => {});
    },
    downloadIndicatori() {
      var headerLabels = this.tableFields.map(function (el) {
        return el.label;
      });
      let header = headerLabels.join(";");
      var dataArray = this.indicatori.map(function (el) {
        return {
          nome_parametro: el.nome_parametro,
          nome_aggregazione: el.nome_aggregazione,
          limite: el.limite,
          valore: el.valore
        };
      });
      let values = dataArray.map((o) => Object.values(o).join(";")).join(";\n");
      let csvData = header + ";\n" + values + ";";

      let anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
      anchor.target = "_blank";
      anchor.download = this.getFileName();
      anchor.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    },
    getFileName() {
      let nomestazione = this.dettaglioStazione.denominazione
        .replace(/ /g, "")
        .replace("'", "");
      let anno = this.indForm.anno;
      return nomestazione + "_indicatori_" + anno + ".csv";
    }
  },
  async mounted() {
    this.fetchProvince();
    var anni = [];
    let current = new Date().getFullYear();
    for (var i = current; i >= 1990; i--) {
      anni.push("" + i);
    }
    this.anni = anni;
  }
};
</script>
